$brand-primary: #2C3E50;
$brand-secondary: #FFF;

a.navbar-brand span {
  font-size: 19px;
}

.login-container {
  margin-top: 25px;

  .lead {
    font-size: 18px;
  }
}

.mb-30 {
  margin-bottom: 30px;
}

.w-100 {
  width: 100% !important;
}

.w-75 {
  width: 75% !important;
}

.w-50 {
  width: 50% !important;
}

.w-25 {
  width: 25% !important;
}

/*
 * Base structure
 */

html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 160px;
  padding: 0px;
  // background-color: #f7f7f7;
}

/*
 * Global add-ons
 */

.hr--space {
  border: none;
}

.sub-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

#spinnerOverlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.7);
}
#spinnerOverlay i {
  position: absolute;
  top: 50vh;
  left: 48vw;
}
.hideSpinner {
  opacity: 0;
  z-index: -100;
  transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out;
}

.showSpinner {
  opacity: 1;
  z-index: 100;
  transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out;
}

.purchased-inspection-label {
  display: inline-block !important;
  min-width: 20px;
  min-height: 18px;
}

.opacity-0 {
  opacity: 0;
}

/*
 * Top navigation
 * Hide default border to remove 1px line.
 */
.navbar-fixed-top {
  border: 0;
}


/*
 * Sidebar
 */

/* Hide for mobile, show later */
.sidebar {
  display: none;
}
@media (min-width: 768px) {
  .sidebar {
    position: fixed;
    top: 51px;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    background-color: #f5f5f5;
    border-right: 1px solid #eee;
  }
}

/* Sidebar navigation */
.nav-sidebar {
  margin-right: -21px; /* 20px padding + 1px border */
  margin-bottom: 20px;
  margin-left: -20px;
}
.nav-sidebar > li > a {
  padding-right: 20px;
  padding-left: 20px;
}
.nav-sidebar > .active > a,
.nav-sidebar > .active > a:hover,
.nav-sidebar > .active > a:focus {
  color: #fff;
  background-color: #428bca;
}




/*
 * Main content
 */

.main {
  padding: 20px;
}
@media (min-width: 768px) {
  .main {
    padding-right: 40px;
    padding-left: 40px;
  }
}
.main .page-header {
  margin-top: 0;
}


/*
 * Signin form
 */

.form-signin {
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 10px;
}
.form-signin .checkbox {
  font-weight: normal;
}
.form-signin .form-control {
  position: relative;
  height: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}


section {
  background-color: white;
}

// TODO: Can this be deleted?
// #mainNav {
//   margin-bottom:0px;
// }

// only pad the container
// if preceded by a main nav
#mainNav + section.wrapper {
  padding-top: 0px;
}
#mainNav {
  margin-bottom: 0px;
}

#sidebar li.active {
  a {
    font-weight:bold;
    color:$brand-primary;
  }

}

.table-hover tbody {
  tr {
    cursor:pointer;
  }
}

.well-dark {
  background-color: $brand-primary;
  color:white;
  margin-left: -25px;
  margin-right: -25px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 0;
  hr {
    border-top: 1px solid #4A6886;
  }
  p {
    margin: 0;
  }
}

// Activity status

.status-completed {
  color:#999;
  label {
    text-decoration: line-through;;
  }
}

.activity-list {
  li {
    border-bottom: 1px solid#eee;
  }
}

.part-header-name {
  margin-right: 5px;
}

.sort-container {
  position: relative;
}

.part-sort-asc, .part-sort-desc {
  position: absolute;
  top: 3px;
  opacity: 0.3;
}

.active-sort {
  opacity: 1 !important;
}

#project-image {
  height:400px;
  .project-header {
    margin-top:23%;
    color:white;
    h3 {
      font-size: 40px;
      font-weight: bold;
      text-shadow: 2px 2px #222;
    }
  }
}
.coverbackground {
  background: no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.intro-well {
  margin-top:30px;
  min-height: 240px;
}

.activity-item {
  background-color: #F3F3F3;
  color: #2C3E50;
  margin: 1px 0 0;
  border-radius: 6px;
  border-bottom: 1px solid #2C3E50;
  cursor:pointer;
  img {
    padding-top: 10px;
    display:none;
  }
  a {
    color:$brand-primary;
  }

}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}


.date, .author, .plan {
  color: lighten($brand-primary, 40% );
}

#headerDock {
  z-index:1000;
  &.collapsed {
    position: fixed;
    top:60px;
    width:100%;

  }
}

#front {
  display: none;
}

.print-front {

}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.pointer {
  cursor: pointer;
}

// Enabling the btn class for breaking lines

a, .btn {
  white-space: normal;
}



// AFFIX TEST
// TODO cleanup css


// DASHBOARD

#dashboard {
  padding-top:30px;
  padding-bottom:30px;
  min-height:250px;
  background:#D8D7D7;
  h1 {
    font-size: 30px;
    line-height: 1;
    padding-top:20px;
  }
}

// FOOTER

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  background-color: #f5f5f5;
  padding: 30px 0px 110px 0px;
  border-bottom:20px solid #2C3E50;
  img {
    max-width: 200px;
    vertical-align: text-top;
  }
}
#footer.footer-home {
  padding: 30px 0px 335px 0px;
  h2 {
    font-size: 22px;
  }
}

@media screen and (max-width: 499px) {
  #footer {
    padding: 30px 0px 220px 0px;
    .contact p, .version p {
      text-align: left;
      float: none !important;
    }
  }
}


// STYLING THE DOCUMENT WHICH CONTAINS THE PLAN

.plan-container {
  h2 {
    margin-top:100px;
  }
}


// Unauthorized

.unauthorized {
  h1 {
    margin-top: 300px;
    margin-bottom: 300px;
  }
}



#masthead {
  min-height: 160px;
  background:#E8E8E8;
}

#masthead h1 {
  font-size: 30px;
  line-height: 1;
  padding-top:20px;
}

#masthead .well {
  margin-top:8%;
  background: none;
}

#mobileNav {
  display:none;
  background-color: #f7f7f7;
  padding-bottom:15px;
}

@media screen and (min-width: 768px) {
  #masthead {
    h1 {
      font-size: 50px;
    }
  }
}





.navbar-bright {
  background-color:#111155;
  color:#fff;
}

.affix-top,.affix{
  position: static;
}

@media (min-width: 979px) {
  #sidebar.affix-top {
    position: static;
    margin-top:30px;
    width:228px;
  }

  #sidebar.affix {
    position: fixed;
    top:70px;
    width:228px;
  }
}

#sidebar li.active {
  border:0 #eee solid;
  border-right-width:5px;
}


// Activities

.year-selector-on {
  display: none;
}

.arrow-navigation {
  margin-top:10px;
}


// sidebar affix
// Transforms to button on less than

@media (max-width: 992px) {
  #masthead {
    min-height: inherit;
    .well {
      display:none;
    }
  }
  #sidebar {
    display:none;
  }
  #mobileNav {
    display:block;
  }
  .headerInfo {
    text-align:center;
  }
}


// LOGIN STYLES

section#login {
  padding:40px 0;
  hr {
    border-top:1px solid lighten($brand-primary, 50%);
  }
  h1 {
    font-size: 40px;
    font-weight: bold;
    text-shadow: 2px 2px #222;
  }
  .project-header {
    margin-top:20px;
    color:white;
  }
}

.login-wrapper {
  text-align: center;
  max-width:400px;
  margin:auto;
  .form-signin {
    width:100%;
    padding: 30px 38px 66px;
    margin: 0 auto;
    background-color: #eee;
    // border: 3px dotted rgba(0,0,0,0.1);
  }
  .form-signin-heading {
    text-align:center;
    margin-bottom: 30px;
  }
  .form-control {
    position: relative;
    font-size: 16px;
    height: auto;
    padding: 10px;
  }
  input[type="text"] {
    margin-bottom: 0px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  input[type="password"] {
    margin-bottom: 20px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .colorgraph {
    height: 7px;
    border-top: 0;
    background: $brand-primary;
    border-radius: 5px;
  }
}

.bg-primary {
  background-color: $brand-primary !important;
  -webkit-print-color-adjust: exact;
}

.nav>li>a {
  padding-right: 10px;
  padding-left:10px;
}

.image-gallery {
  >div {
    margin-bottom: 30px;
  }
}

.row-edit {
  td {
    cursor: pointer;

  }
  td:hover {
    background:red;
  }
}

.alert-notifier-container {
  position: fixed;
  top: 70px;
  right: 20px;
  padding: 5px;
  z-index: 10001;

  .alert {
    display: inline-block;
    float: right;
  }

  .alert-enter {
    opacity: 0.01;
    transition: opacity .25s ease-in;
  }

  .alert-enter.alert-enter-active {
    opacity: 1;
  }

  .alert-leave {
    opacity: 1;
    transition: opacity .25s ease-in;
  }

  .alert-leave.alert-leave-active {
    opacity: 0.01;
  }
}

.autosave {
  margin: 6px 6px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  & > span {
    color: #888888;
    padding: 6px;
    display: inline-block;
  }
}

.action-bar {
  margin-top: 10px;
}

@media screen and (max-width:767px){
  .autosave {
    float: none !important;
    margin: 0px !important;
    span {
      padding: 0px;
    }
  }
  .action-bar.plan-actions {
    .btn {
      width: 48%;
      padding: 5px;
      margin-right: 5px;
      margin-top: 5px;
    }
  }
}

@media screen and (max-width:992px){
  .autosave {
    margin-top: -40px;
  }
  .action-bar {
    .btn {
      margin-right: 5px;
    }
  }
}

.input-group-addon, .borderless {
  border: none;
}

a.label {
  text-decoration: none !important;
}

.sub-header {
  background-color: white;
  z-index: 10000;
  margin: 0px;
}

.sticky {
  padding-bottom: 0px;

  & h1 {
    margin-top: 10px;
  }
  & .action-bar {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.distribution-table {
  input {
    width: 35px;
    padding: 2px;
  }
  tfoot {
    font-weight:bold;
    // border-top:1px solid #666;
    // border-bottom:1px solid #666;
  }
  tr {
    th:last-child {
      text-align: right;
    }
    td:last-child {
      text-align: right;
    }
  }
  .has-error {
    border: 2px solid #E74C3C;
  }
  .highlighted {
    background-color: #ccc
  }
  .highlighted.establishment {
    background-color: #F39C12;
  }
  hr {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.free-form-total {
  font-weight: 400 !important;
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
}
.distribution-table-customer {
  border: 1px solid white;
  // table-layout: fixed;
  // overflow: hidden;

  font-size: 12px;
  tr > th, tr > td {
    border: 1px solid white !important;
    min-width: 64px;
    max-width: 64px;
    width: 100%;
  }
  tr th:first-of-type, tr td:first-of-type {
    min-width: 240px;
    max-width: 240px;
    text-align: left;
  }
  tr > th {
    text-align: center;
  }
  tr > td {
    text-align: right;
    a {
        cursor: pointer;
    }
  }
  tbody, thead, tfoot {
    display: block;
  }
  thead {
    border-bottom: 1px solid #666;
  }
  tfoot {
    border-top: 1px solid #666;
    tr:last-of-type {
      font-weight: bold;
      font-size: 13px;
    }
  }

  tbody {
    overflow: auto;
    height: 500px;
    // margin-right: -17px;
  }

}

.activity-table {
  tbody:after {
    content: '';
    display: block;
    height: 25px;
  }
  hr {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  tr td:last-of-type, tr th:last-of-type {
    text-align: right;
  }
}

.uploadSpinner {
  width: 100%;
  height: 180px;
  padding-top: 40px;
  text-align: center;

  span {
    display: block;
  }
}

// CHARTIST TWEAKS

.ct-series-a .ct-bar {
  stroke: #F39C12 !important;
  stroke-width: 20px;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.8) !important;
  color: rgba(0, 0, 0, 0.8) !important;
  font-size: 1.4rem !important;
  font-weight: bold !important;
}

@media (max-width: 768px) {
  .ct-label {
    font-size: 0.9rem !important;
  }
}

// Area pie chart
.ct-series-a .ct-slice-pie {
  fill: #F39C12 !important;
}

.ct-series-b .ct-slice-pie {
  fill: #18bc9c !important;
}

.ct-series-p .ct-slice-pie, .ct-series-p .ct-area {
  fill: #a94442;
}

.ct-series-q .ct-slice-pie, .ct-series-q .ct-area {
  fill: #3c763d;
}

.ct-series-r .ct-slice-pie, .ct-series-r .ct-area {
  fill: #2C3E50;
}

.ct-series-s .ct-slice-pie, .ct-series-s .ct-area {
  fill: #5bc0de;
}

.bips-pie-chart {
  margin-bottom: 40px;
  .ct-label {
    font-size: 1.2rem !important;
  }
}


.table-cell-highlight {
  background-color: #f3f3f3;
  font-weight: bold;
}

.sticky-fullwidth {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
}

.part-item {
  cursor: pointer;
  h5 {
    height: 32px;
  }
  // .well {
  //   min-height: 260px;
  // }
}

.part-switch-links a {
  text-decoration: none;
}

.header-actions {
  margin-top:64px;
  font-size:18px;
}

#masthead h2 {
  margin-top:50px;
}

.activity-navigation {
  margin-top:30px;
}

.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

tr.deleted {
  color: #aaaaaa;
  .email {
    text-decoration: line-through;
  }
}

// Styles for react-bootstrap-modal
.animated {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}

.modal {
  display: block;
}

.modal-content>.close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.customer-data {
  label {
    font-size: 12px;
  }
}

@media (min-width:768px) {
  .modal-sm>.modal-dialog {
    width: 300px;
  }
}

@media (min-width:992px) {
  .modal-lg>.modal-dialog {
    width: 900px;
  }
}

.flex-grow {
  flex-grow: 1;
}

.m-0 {
  margin: 0;
}

.mb-1 {
  margin-bottom: 10px;
}

.print-text {
  font-size: 12px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-stretch {
  justify-content: stretch;
}

.logo-text {
  max-width: 100%;
  text-align: left;
}

.print-contact-info {
  align-self: flex-start;
}

.print-front-img-container {
  width: 331px;
}

///PRINT
/////////////////////////////////////////////

.distribution-table-customer-print {
  font-size: 9px;
  tr > th, tr > td {
    min-width: 15px;
    max-width: 15px;
  }
  tr th:first-of-type, tr td:first-of-type {
    min-width: 100px;
    max-width: 100px;
    text-align: left;
  }
  tr > th {
    text-align: center;
  }
  tr > td {
    text-align: right;
  }
  thead {
    display: table-header-group;
  }
  td, th {
    padding: 2px !important;
  }
}

.distribution-wrapper-customer-print {
  h2 {
    font-size: 15px;
  }
}

.chart-print {
  height: 600px;
  width: 800px;
}

.margin-auto {
  margin: auto;
}

.inspection-tooltip {
  width: 300px;
  border: 1px solid black;
  opacity: 1 !important;
  text-align: left;
}

.header-img-container {
  margin-bottom: 30px;
}

.print-header-info {

  h3 {
    margin: 0;
  }
}

@media print {
  #front {
    display: block;
    page-break-after: always;

    img {
      height: 500px;
      max-height: 500px;
    }

    .plan-1-logo {
      height: 92px;
      width: 100%;
    }
  }

  #back {
    display: block;
    page-break-before: always;

    .card {
      margin: 20px;
      border: 1px solid black;
      border-radius: 10px;
      padding: 20px;
      height: 320px;
      flex: 0 45%;
    }

    .card.logo-container{
      position: relative;

      img {
        width: 200px;
        height: 59px;
        position: absolute;
        top: 75%;
        left: 50%;
      }
    }
  }

  html, body {
    height: 99%;
  }
  body {
    background-color: white;
  }
  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
  p {
    font-size: 12px;
  }
  .well {
    padding: 5px;
  }
  .headerInfo {
    text-align: left;
  }
  #masthead {
    min-height: auto;
    h1 {
      padding-top: 0px;
    }
    h2 {
      margin-top: 10px;
    }
  }
  #footer {
    border-bottom: none;
  }
  .customer-wrapper {
    padding-top: 0px;
  }
  .page-break {
    page-break-after: always;
  }
  .page-break-before {
    page-break-before: always;
  }
}

.dropzone {
  width: 100%;
  height: 200px;
  border-width: 2px;
  border-color: #666;
  border-style: dashed;
  border-radius: 5px;
  text-align: center;
  padding-top: 7%;
  font-size: 18px;
  color: #666;
  &.dropzoneActive {
    border-style: solid;
    background-color: rgb(238,238,238)
  }
  &.dropzoneReject {
    border-style: solid;
  }
}
.dropzone.part-note {
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.image-gallery {
  .well-sm {
    background-color: #ecf0f1;
  }
  .file-title {
    height: 42px;
    overflow: hidden;
  }
  &.customer {
    margin-top: 20px;
  }
}

.hovereffect {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
  background: #2C3E50;
  margin-bottom: 10px;
}

.hovereffect .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  padding: 50px 20px;
  .file-icon {
    color: white;
    font-size: 25px;
    position: absolute;
    right: 5px;
    top: 5px;
    &.fa-file-pdf-o {
      color: #FF001B;
    }
  }
}

.hovereffect img {
  display: block;
  position: relative;
  max-width: none;
  width: 100%;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.hovereffect:hover img {
  opacity: 0.4;
  filter: alpha(opacity=40);
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}

.uploading {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
  background: #2C3E50;
  margin-bottom: 10px;
  .file-progress {
    color: white;
  }
  .overlay {
    padding: 20px 20px;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
  }
  .rc-progress-circle {
    height: 100px;
  }
  img, embed {
    display: block;
    position: relative;
    max-width: none;
    width: auto;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    opacity: 0.4;
    filter: alpha(opacity=40);
    overflow: hidden;
  }
}


.hovereffect a, .hovereffect p {
  color: #FFF;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(100%,0,0);
  transform: translate3d(100%,0,0);
}

.hovereffect:hover a, .hovereffect:hover p {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

@media (min-width: 320px) {
  .uploading img, .uploading embed, .uploader img {
    height: 192px;
  }
}

@media (min-width: 768px) {
  .uploading img, .uploading embed, .uploader img {
    height: 142.84px;
  }
}

@media (min-width: 1024px) {
  .uploading img, .uploading embed, .uploader img {
    height: 137.54px;
  }
}

@media (min-width: 1025px) {
  .uploading img, .uploading embed, .uploader img {
    height: 172.9px;
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}

.landing {
  section {
    margin-top: 30px;
  }
}

///// Bootstrap navbar collapse overide

@media (max-width: 992px) {
  .navbar-header {
    float: none;
  }
  .navbar-left,.navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none!important;
  }
  .navbar-nav {
    float: none!important;
    margin-top: 7.5px;
  }
  .navbar-nav>li {
    float: none;
  }
  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in{
    display:block !important;
  }
}

.marketing-wrapper {
  padding-bottom: 240px;
  .marketing-cover {
    height: 400px;
    margin: 10px 0px 40px 0px;
    .marketing-header {
      margin-top:16%;
      color:white;
      h1 {
        font-size: 50px;
        font-weight: bold;
        // text-shadow: 2px 2px #222;
      }
    }
  }
  .page-header {
    color: $brand-primary;
    text-decoration: none;
  }
}

.portfolio-client {
  .ct-label.ct-horizontal {
    font-size: 1.6rem !important;
  }
  .meter-pie-chart.print {
    width: 600px;
    height: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  .bips-pie-chart.print {
    width: 1000px;
    height: 700px;
    margin-left: auto;
    margin-right: auto;
  }
  .portfolio-meta {
    a {
        color: #FFF;
    }
    .well {
      background: $brand-primary;
      color: white;
    }
    h3 {
      margin-top: 0;
    }
  }
}

.section-background {
  background: #f1f1f1;
}

.print-button {
  font-size: 19px;
}

.text-muted-dark {
  color: #7c8083;
}

.text-muted-small {
  font-size: 12px;
}


// Energy feature


#energy-label {
    max-width: 560px;
    margin-bottom: 40px;
  .green-text {
    color: $brand-secondary;
  }

  .energy-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    h5 {
        margin: 0;
    }
  }

  .energy-text {
    margin-top: 20px;
  }

  .energy-seperator {
    height: 35px;
    margin-top: 21px;
    margin-bottom: 10.5px;

    @media print {
      height: 24px;
    }
  }
  .energy-column-current {
    .energy-column {
        div {
            width: 80px;
            height: 80px;
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            .label-char-sm {
                font-size: 40px;
                line-height: 40px;
            }
            .label-year {
                bottom: 10px;
                font-size: 16px;
                line-height: 16px;
            }
        }
    }    
  }
  .energy-column {
        div {
        position: relative;
        height: 50px;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        .label-char {
          font-size: 24px;
          line-height: 40px;
          font-weight: 600;
        }
        .label-char-sm {
          font-size: 24px;
          line-height: 32px;
          font-weight: 600;
        }
        .label-year {
          position: absolute;
          font-size: 12px;
          line-height: 12px;
          bottom: 5px;
          width: 100%;
          left: 0;
        }
      }

      .bg-a2020 {
        background-color: #0B7A36;
      }
      .bg-a2013 {
        background-color: #3AB549;
      }
      .bg-a2010 {
        background-color: #8CC63E;
      }
      .bg-b {
        background-color: #73A532;
      }
      .bg-c {
        background-color: #D19F09;
      }
      .bg-d {
        background-color: #CC7A16;
      }
      .bg-e {
        background-color: #F1592A;
      }
      .bg-f {
        background-color: #7B0B0E;
      }
      .bg-g {
        background-color: #552B5B;
      }
    }
  .grid {
    .energy-column-container {
        display: flex;
        justify-content: space-between;
        gap: 15px;
        .energy-column {
            width: calc(100% / 9);
        }
    }
  }
  .arrow-wrapper {
    margin-left: -20px;
  }
  .arrow-position-a2020 {
    margin-left: 5.555%;
  }
  .arrow-position-a2013 {
    margin-left: 16.665%;
  }
  .arrow-position-a2010 {
    margin-left: 27.775%;
  }
  .arrow-position-b {
    margin-left: 38.885%;
  }
  .arrow-position-c {
    margin-left: 49.995%;
  }
  .arrow-position-d {
    margin-left: 61.105%;
  }
  .arrow-position-e {
    margin-left: 72.215%;
  }
  .arrow-position-f {
    margin-left: 83.325%;
  }
  .arrow-position-g {
    margin-left: 94.435%;
  }
  .arrow-text {
    margin-left: -22px;
    text-align: center;
    display: inline-block;
  }
  .arrow-down {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #2C3E50;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .arrow-up {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #bdbaba;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.pdf-responsive {
    max-width: 100%;
    height: auto;
    display: block;
    min-height: 150px;
}


.nav-icon {
    fill: #fff;
}

.logo-icon {
    fill: #2e4c5f;
}

.react-datepicker-wrapper {
    display: block;
    .react-datepicker__input-container {
        display: block;
    }
}

.label-round {
    width: 15px;
    height: 15px;
    display: inline-block;
    padding: 0;
    vertical-align: middle;
    margin-right: 4px;
    border-radius: 50%;
}

.label-now {
    background-color: #2e4c5f;
}

@media print {
    @page {size: landscape}
    .MuiAppBar-root {
        display: none !important;
    }
    .MuiDrawer-root {
        display: none !important;
    }
}